import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apiGetGalleryList,
  apiGetImage,
  apiGetRandomAlbum,
  apiGetRandomImageIndex,
} from "../Api/ApiFunctions";
import GalleryPreview from "../Components/GalleryPreview";
import { ImageSize } from "../Models/ImageModel";

const MainPage = (): JSX.Element => {
  const [galleryList, setGalleries] = useState<string[]>([]);
  const previewAlbums = useRef<string[]>([]);
  const previewIndices = useRef<number[]>([]);
  const [galleryPreviews, setGalleryPreviews] = useState<string[]>([]);
  const [loadedX, setLoadedX] = useState<boolean>(false);
  const loadIndex = useRef<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchGalleries = async () => {
      let galleryList = await apiGetGalleryList();

      galleryList.sort();

      setGalleries(galleryList);
    };

    fetchGalleries();
  }, []);

  useEffect(() => {
    if (galleryList.length === 0) {
      return;
    }
    if (loadIndex.current >= galleryList.length) {
      if (!loadedX) {
        setLoadedX(true);
        loadIndex.current = 0;
      }
      return;
    }
    const fetchPreview = async () => {
      let gallery = galleryList[loadIndex.current];
      if (loadedX) {
        let imageSize = ImageSize.s;
        let preview = await apiGetImage(
          gallery,
          previewAlbums.current[loadIndex.current],
          previewIndices.current[loadIndex.current],
          imageSize
        );

        let newPreviews = [...galleryPreviews];
        newPreviews[loadIndex.current] = preview;
        setGalleryPreviews(newPreviews);
      } else {
        let album = await apiGetRandomAlbum(gallery);
        let index = await apiGetRandomImageIndex(gallery, album);
        let imageSize = ImageSize.x;
        let preview = await apiGetImage(gallery, album, index, imageSize);
        previewIndices.current.push(index);
        previewAlbums.current.push(album);

        setGalleryPreviews([...galleryPreviews, preview]);
      }
      loadIndex.current++;
    };
    fetchPreview();
  }, [galleryList, galleryPreviews, loadedX]);

  return galleryList.length === 0 ? (
    <h1>Loading ...</h1>
  ) : (
    <div>
      {galleryList.map((gallery, i) => (
        <div key={i}>
          <GalleryPreview name={gallery} image={galleryPreviews[i]} />
        </div>
      ))}
    </div>
  );
};

export default MainPage;
