import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiDeleteEssay,
  apiGetEssayData,
  apiPostEssay,
} from "../Api/ApiFunctions";

const EssayPage = (props: {
  hasAdminAccess: boolean;
  updateEssayList: () => void;
}): JSX.Element => {
  const { essayName } = useParams();
  const [essayData, setEssayData] = useState("");

  const navigate = useNavigate();

  const getEssayContentElement = (essayContent: string): JSX.Element => {
    const textArr: string[] = essayContent.split("\n").filter((v) => v !== "");
    return (
      <div>
        {textArr.map((text, i) => {
          if (text.substring(0, 4) === "http") {
            return <img key={i} src={text} />;
          }
          return <div key={i}>{text}</div>;
        })}
      </div>
    );
  };

  useEffect(() => {
    if (essayName === undefined) {
      return;
    }
    const fetchEssayData = async () => {
      setEssayData(await apiGetEssayData(essayName));
    };
    fetchEssayData();
  }, [essayName]);

  if (!essayName || !essayData) {
    return <div>404 Page Not Found</div>;
  }

  return (
    <div className="essay-page">
      <div className="header">
        <h1
          className="title"
          contentEditable={props.hasAdminAccess}
          suppressContentEditableWarning={props.hasAdminAccess}
          onBlur={async (e) => {
            await apiDeleteEssay(essayName);
            await apiPostEssay(e.target.innerText, essayData);
            props.updateEssayList();
            navigate(`/essays/${e.target.innerText}`);
          }}
        >
          {essayName}
        </h1>
        <span className="button" onClick={() => navigate(`/essays`)}>
          &larr; Back
        </span>
        {props.hasAdminAccess && (
          <span
            className="button"
            onClick={async () => {
              if (window.confirm('Delete "' + essayName + '"?')) {
                await apiDeleteEssay(essayName);
                props.updateEssayList();
                navigate(`/essays`);
              }
            }}
          >
            Delete
          </span>
        )}
      </div>
      <div
        className="essay-content"
        contentEditable={props.hasAdminAccess}
        suppressContentEditableWarning={props.hasAdminAccess}
        onBlur={(e) => {
          const newData = e.target.innerText;
          if (newData) {
            apiPostEssay(essayName, newData);
          }
        }}
      >
        {props.hasAdminAccess ? essayData : getEssayContentElement(essayData)}
      </div>
    </div>
  );
};

export default EssayPage;
