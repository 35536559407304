import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { apiUpdateBackendCache } from "../Api/ApiFunctions";

const AdminPage = (props: { hasAdminAccess: boolean }): JSX.Element => {
  const [tokenInput, setTokenInput] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [cookies, setCookies] = useCookies(["auth_token"]);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.auth_token !== token && token !== "") {
      setCookies("auth_token", token, {
        path: "/",
        secure: false,
        httpOnly: false,
        // sameSite: "none"
      });
      setToken("");
    }
  }, [cookies.auth_token, setCookies, token]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setToken(tokenInput);
    setTokenInput("");
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTokenInput(e.currentTarget.value);
  };

  return (
    <div>
      <div className="header">
        <h1>Admin Login</h1>
        <span className="button" onClick={() => navigate(`/`)}>
          &larr; Back
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <label>Admin Token: </label>
        <input type={"password"} onChange={handleChange} value={tokenInput} />
        <input type={"submit"} value={"Enter"} disabled={tokenInput === ""} />
      </form>
      {props.hasAdminAccess && (
        <button
          onClick={() => {
            alert("WAIT FOR THE NEXT ALERT");
            apiUpdateBackendCache();
          }}
        >
          Update Cache
        </button>
      )}
    </div>
  );
};

export default AdminPage;
