import axios from "axios";
import { Cookies } from "react-cookie";
import { ImageInfo, ImageSaleStatus, ImageSize } from "../Models/ImageModel";
import ServerURL from "./ServerURL";

const getList = async (url: string): Promise<string[]> => {
  const res = await axios.get(url);
  let data = res.data;
  if (typeof data != "string") {
    data = JSON.stringify(res.data);
  }
  if (data.length === 0) {
    return [];
  }
  return data.split("\n");
};

export const apiGetEssayList = async (): Promise<string[]> => {
  return getList(`${ServerURL}/essay_list`);
};

export const apiGetGalleryList = async (): Promise<string[]> => {
  return getList(`${ServerURL}/gallery_list`);
};

export const apiGetAlbumList = async (gallery: string): Promise<string[]> => {
  return getList(`${ServerURL}/album_list?gallery=${gallery}`);
};

export const apiGetAlbumLength = async (
  gallery: string,
  album: string
): Promise<number> => {
  const res = await axios.get(
    `${ServerURL}/album_length?gallery=${gallery}&album=${album}`
  );
  return parseInt(res.data);
};

export const apiGetImage = async (
  gallery: string,
  album: string,
  index: number,
  size: ImageSize
): Promise<string> => {
  const res = await axios.get(
    `${ServerURL}/image?gallery=${gallery}&album=${album}&index=${index}&size=${size}`
  );
  return res.data;
};

export const apiGetEssayData = async (essayName: string): Promise<string> => {
  const res = await axios.get(
    `${ServerURL}/essay_data?essay_name=${essayName}`
  );
  return res.data;
};

export const apiGetImageInfo = async (
  gallery: string,
  album: string,
  index: number
): Promise<ImageInfo> => {
  const res = await axios.get(
    `${ServerURL}/image_info?gallery=${gallery}&album=${album}&index=${index}`
  );
  if (res.data === "") {
    return { name: "", saleStatus: ImageSaleStatus.NotForSale, saleLink: "" };
  }
  return res.data;
};

export const apiGetRandomAlbum = async (gallery: string): Promise<string> => {
  const res = await axios.get(`${ServerURL}/album_random?gallery=${gallery}`);
  if (typeof res.data !== "string") {
    return JSON.stringify(res.data);
  }
  return res.data;
};

export const apiGetRandomImageIndex = async (
  gallery: string,
  album: string
): Promise<number> => {
  const res = await axios.get(
    `${ServerURL}/image_index_random?gallery=${gallery}&album=${album}`
  );
  return parseInt(res.data);
};

export const apiGetHasAdminAccess = async () => {
  let token = new Cookies().get("auth_token");
  const res = await axios.get(`${ServerURL}/has_admin`, {
    // withCredentials: true,
    headers: {
      token: token,
    },
  });
  return res.data;
};

export const apiPostEssay = async (essayName: string, essayData: string) => {
  let token = new Cookies().get("auth_token");
  await axios.post(`${ServerURL}/essay?essay_name=${essayName}`, essayData, {
    headers: {
      token: token,
    },
  });
};

export const apiPostPhoto = async (
  imageData: Uint8Array,
  gallery: string,
  album: string
) => {
  let token = new Cookies().get("auth_token");
  await axios.post(
    `${ServerURL}/image?gallery=${gallery}&album=${album}`,
    imageData,
    {
      // withCredentials: true,
      headers: {
        token: token,
      },
    }
  );
};

export const apiPostImageInfo = async (
  gallery: string,
  album: string,
  index: number,
  imageInfo: ImageInfo
) => {
  let token = new Cookies().get("auth_token");
  await axios.post(
    `${ServerURL}/image_info?gallery=${gallery}&album=${album}&index=${index}`,
    imageInfo,
    {
      // withCredentials: true,
      headers: {
        token: token,
      },
    }
  );
};

export const apiDeleteEssay = async (essayName: string) => {
  let token = new Cookies().get("auth_token");

  await axios.delete(`${ServerURL}/essay?essay_name=${essayName}`, {
    headers: {
      token: token,
    },
  });
};

export const apiDeletePhoto = async (
  gallery: string,
  album: string,
  index: number
) => {
  let token = new Cookies().get("auth_token");
  await axios.delete(
    `${ServerURL}/image?gallery=${gallery}&album=${album}&index=${index}`,
    {
      // withCredentials: true,
      headers: {
        token: token,
      },
    }
  );
};

export const apiPostAlbum = async (gallery: string, album: string) => {
  let token = new Cookies().get("auth_token");
  await axios.post(
    `${ServerURL}/album?gallery=${gallery}&album=${album}`,
    {},
    {
      // withCredentials: true,
      headers: {
        token: token,
      },
    }
  );
};

export const apiDeleteAlbum = async (gallery: string, album: string) => {
  let token = new Cookies().get("auth_token");
  await axios.delete(`${ServerURL}/album?gallery=${gallery}&album=${album}`, {
    // withCredentials: true,
    headers: {
      token: token,
    },
  });
};

export const apiUpdateBackendCache = async () => {
  let token = new Cookies().get("auth_token");
  await axios.post(
    `${ServerURL}/cache`,
    {},
    {
      headers: {
        token: token,
      },
    }
  );
  alert("Updated Backend Cache");
};

export const apiPostGalleryAlias = async (gallery: string, alias: string) => {
  let token = new Cookies().get("auth_token");
  await axios.post(
    `${ServerURL}/gallery_alias?gallery=${gallery}&alias=${alias}`,
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};
