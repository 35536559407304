import { useNavigate } from "react-router-dom";
import { ImageSize } from "../Models/ImageModel";
import Image from "./Image";

const GalleryPreview = (props: {
  name: string;
  image: string | undefined;
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div
      className="gallery-preview"
      onClick={() => navigate(`/gallery/${props.name}`)}
    >
      <span className="title-bg">
        <h1 className="title">{props.name}</h1>
      </span>

      {props.image !== undefined && (
        <Image size={ImageSize.x} src={props.image} />
      )}
    </div>
  );
};

export default GalleryPreview;
