import { useNavigate } from "react-router-dom";

const AboutMePage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="aboutme-page">
      <div className="header">
        <img src="portrait.jpeg" alt="Roger Xiao" />
        <br />
        <h1 className="title">Roger Xiao</h1>
        <br />
        <span className="button" onClick={() => navigate("/")}>
          &larr; Back
        </span>
      </div>
      <div className="text-section">
        <p>
          Roger Xiao is an artist and an architect, good at house design,
          including exterior and interior.
        </p>
        <p>
          He often does many sketches when traveling. For example, he made about
          100 sketches when he traveled around the Northern America in this
          summer. If you like sketch works, please look through his{" "}
          <a href="/#/gallery/Sketch%20Collection"> collection</a> in this web
          site.
        </p>
        <p>
          Before he immigrated to Canada, many buildings in China had been
          designed by Roger. Now, he focuses on house design and he wants to
          finish a work from interior design to landscaping, because he thinks
          the style of a house need to be pure and harmonious.
        </p>
        <p>
          If you want to find a good house designer, please contact him via
          email: <a href="mailto: xiaolb@yahoo.com">xiaolb@yahoo.com</a>
        </p>
      </div>
      <button
        className="admin-page-button"
        onClick={() => {
          navigate("/admin");
        }}
      >
        Edit
      </button>
    </div>
  );
};

export default AboutMePage;
