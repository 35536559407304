import AlbumPreview from "../Components/AlbumPreview";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import AlbumPage from "./AlbumPage";
import {
  apiGetImage,
  apiGetAlbumLength,
  apiGetAlbumList,
  apiPostAlbum,
  apiPostGalleryAlias,
} from "../Api/ApiFunctions";
import { AlbumProps } from "../Models/AlbumProps";
import { ImageSize } from "../Models/ImageModel";

const GalleryPage = (props: { hasAdminAccess: boolean }): JSX.Element => {
  const [albumPreviews, setAlbumPreviews] = useState<AlbumProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadedX, setLoadedX] = useState<boolean>(false);
  const previewIndices = useRef<number[]>([]);
  const loadIndex = useRef<number>(0);
  const [gallery, setGallery] = useState<string>("");

  const [newAlbumInput, setNewAlbumInput] = useState<string>("");

  const { galleryName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!galleryName) {
      return;
    }

    setAlbumPreviews([]);
    setIsLoading(true);
    setLoadedX(false);
    previewIndices.current = [];
    loadIndex.current = 0;

    const getAlbums = async () => {
      let albumList = await apiGetAlbumList(galleryName);

      let albums: AlbumProps[] = [];
      for (let album of albumList) {
        albums.push({ name: album, images: [] });
      }

      setAlbumPreviews(albums);
      setIsLoading(false);
    };
    getAlbums();
    setGallery(galleryName);
  }, [galleryName]);

  useEffect(() => {
    if (!gallery || albumPreviews.length === 0) {
      return;
    }

    const fetchPreview = async () => {
      let index = loadIndex.current;
      if (index >= albumPreviews.length) {
        if (!loadedX) {
          setLoadedX(true);
          loadIndex.current = 0;
        }
        return;
      }
      const imageSize = !loadedX ? ImageSize.x : ImageSize.s;

      if (!loadedX) {
        previewIndices.current.push(
          Math.floor(
            Math.random() *
              (await apiGetAlbumLength(
                gallery,
                albumPreviews[loadIndex.current].name
              ))
          )
        );
      }

      const albumPreview = {
        name: albumPreviews[index].name,
        images: [
          await apiGetImage(
            gallery,
            albumPreviews[index].name,
            previewIndices.current[index],
            imageSize
          ),
        ],
      };
      if (index < albumPreviews.length) {
        let newAlbumPreviews = [...albumPreviews];
        newAlbumPreviews[index] = albumPreview;
        setAlbumPreviews(newAlbumPreviews);
      } else {
        setAlbumPreviews([...albumPreviews, albumPreview]);
      }
      loadIndex.current++;
    };

    fetchPreview();
  }, [loadedX, albumPreviews, gallery]);

  const handleCreateAlbum = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newAlbumInput.trim() === "") {
      return;
    }
    await apiPostAlbum(gallery, newAlbumInput);
    window.location.reload();
  };

  const handleRenameGallery = async (e: React.FormEvent<HTMLInputElement>) => {
    const alias = e.currentTarget.value;
    await apiPostGalleryAlias(gallery, alias);
    navigate(`/gallery/${alias}`);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div>
            {isLoading ? (
              <h1>Loading ...</h1>
            ) : albumPreviews.length !== 0 ? (
              <div>
                <div className="header">
                  {props.hasAdminAccess ? (
                    <div>
                      <input
                        className="title"
                        defaultValue={galleryName}
                        onBlur={handleRenameGallery}
                      />
                    </div>
                  ) : (
                    <h1 className="title">{galleryName}</h1>
                  )}
                  <span className="button" onClick={() => navigate(`/`)}>
                    &larr; Back
                  </span>
                </div>

                {albumPreviews.map((album, i) => (
                  <AlbumPreview key={i} {...album} />
                ))}
                {props.hasAdminAccess && (
                  <div>
                    <h1>New Album</h1>
                    <form onSubmit={handleCreateAlbum}>
                      <input
                        type={"text"}
                        value={newAlbumInput}
                        onChange={(e) => {
                          setNewAlbumInput(e.currentTarget.value);
                        }}
                      />
                      <input
                        type={"submit"}
                        value={"Create Album"}
                        disabled={newAlbumInput === ""}
                      />
                    </form>
                  </div>
                )}
              </div>
            ) : (
              <h1>404 Page Not Found</h1>
            )}
          </div>
        }
      />
      <Route
        path="/album/:albumName/*"
        element={<AlbumPage hasAdminAccess={props.hasAdminAccess} />}
      />
      <Route path="*" element={<h1>404 Page Not Found</h1>} />
    </Routes>
  );
};

export default GalleryPage;
