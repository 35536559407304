import { HashRouter, Route, Routes } from "react-router-dom";
import GalleryPage from "./Pages/GalleryPage";
import MainPage from "./Pages/MainPage";
import AdminPage from "./Pages/AdminPage";
import AboutMePage from "./Pages/AboutMePage";
import { useEffect, useState } from "react";
import { apiGetHasAdminAccess } from "./Api/ApiFunctions";
import { useCookies } from "react-cookie";
import EssayPage from "./Pages/EssayPage";
import EssayListPage from "./Pages/EssayListPage";

const App = (): JSX.Element => {
  const [hasAdminAccess, setHasAdminAccess] = useState<boolean>(false);
  const [cookies, setCookies] = useCookies(["auth_token"]);

  useEffect(() => {
    const fetchAdminAccess = async () => {
      setHasAdminAccess(await apiGetHasAdminAccess());
    };
    fetchAdminAccess();
  }, []);

  useEffect(() => {
    if (cookies.auth_token === "" || cookies.auth_token === undefined) {
      return;
    }
    const fetchAdminAccess = async () => {
      const hasAdmin = await apiGetHasAdminAccess();
      if (hasAdmin) {
        console.log("You have admin access");
      } else {
        alert("Wrong Admin Token");
        setCookies("auth_token", "", {
          path: "/",
          secure: false,
          httpOnly: false,
        });
      }
      setHasAdminAccess(hasAdmin);
    };
    fetchAdminAccess();
  }, [cookies.auth_token, setCookies]);

  return (
    <div className="app">
      <nav className="navbar">
        <a href="/#/">Portfolio</a>
        <a href="/#/essays">Essays</a>
        <a href="/#/aboutme">About Me</a>
      </nav>
      <HashRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route
            path="/gallery/:galleryName/*"
            element={<GalleryPage hasAdminAccess={hasAdminAccess} />}
          />
          <Route
            path="/essays/*"
            element={<EssayListPage hasAdminAccess={hasAdminAccess} />}
          />
          <Route
            path="/admin/*"
            element={<AdminPage hasAdminAccess={hasAdminAccess} />}
          />
          <Route path="/aboutme/*" element={<AboutMePage />} />
          <Route path="*" element={<div>404 Page Not Found</div>} />
        </Routes>
      </HashRouter>
    </div>
  );
};

export default App;
