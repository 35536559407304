import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { apiGetEssayList, apiPostEssay } from "../Api/ApiFunctions";
import EssayPage from "./EssayPage";

const EssayListPage = (props: { hasAdminAccess: boolean }): JSX.Element => {
  const navigate = useNavigate();
  const [essays, setEssays] = useState<string[]>([]);

  const updateEssayList = async () => {
    setEssays(await apiGetEssayList());
  };

  useEffect(() => {
    updateEssayList();
  }, []);

  return (
    <Routes>
      <Route
        path="/:essayName"
        element={
          <EssayPage
            hasAdminAccess={props.hasAdminAccess}
            updateEssayList={updateEssayList}
          />
        }
      />
      <Route
        path="/"
        element={
          <div>
            <div className="header">
              <h1 className="title">Essays</h1>
              <span className="button" onClick={() => navigate(`/`)}>
                &larr; Back
              </span>
            </div>
            <div className="essay-list">
              {essays.map((essayName, i) => (
                <div key={i} onClick={() => navigate(`/essays/${essayName}`)}>
                  <h2>{essayName}</h2>
                </div>
              ))}
              {props.hasAdminAccess && (
                <>
                  <br />
                  <div
                    onClick={async () => {
                      await apiPostEssay("NEW ESSAY", "...");
                      await updateEssayList();
                    }}
                  >
                    <h2>New Essay</h2>
                  </div>
                </>
              )}
            </div>
          </div>
        }
      />
    </Routes>
  );
};

export default EssayListPage;
