import { ImageSize } from "../Models/ImageModel";

const Image = (props: {
  src: string;
  size: ImageSize;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <img
      className={"image " + props.size}
      src={!props.src ? "loading.png" : `data:image/jpeg;base64,${props.src}`}
      alt="loading..."
      onClick={props.onClick}
    />
  );
};

export default Image;
