export enum ImageSaleStatus {
  NotForSale = "Not for sale",
  ForSale = "For sale",
}

export interface ImageInfo {
  name: string;
  saleStatus: ImageSaleStatus;
  saleLink: string;
}

export enum ImageSize {
  x = "x",
  s = "s",
  m = "m",
}
