import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  apiGetImage,
  apiGetImageInfo,
  apiPostImageInfo,
} from "../Api/ApiFunctions";
import Image from "../Components/Image";
import { ImageInfo, ImageSaleStatus, ImageSize } from "../Models/ImageModel";

const ImagePage = (props: {
  hasAdminAccess: boolean;
  src?: string;
  info?: ImageInfo;
  handleImageDelete: () => void;
  handleBackButton: () => void;
}): JSX.Element => {
  const [imageInfo, setImageInfo] = useState<ImageInfo>({
    name: "",
    saleStatus: ImageSaleStatus.NotForSale,
    saleLink: "",
  });
  const [imageData, setImageData] = useState<string>("");
  const [loadingSize, setLoadingSize] = useState<ImageSize>(ImageSize.x);

  const { galleryName, albumName, index } = useParams();

  useEffect(() => {
    if (props.info) {
      setImageInfo(props.info);
      return;
    }
    const getImageName = async () => {
      if (galleryName && albumName && index) {
        setImageInfo(
          await apiGetImageInfo(galleryName, albumName, parseInt(index))
        );
      }
    };
    getImageName();
  }, [albumName, galleryName, index, props.info]);

  useEffect(() => {
    if (props.src) {
      setImageData(props.src);
      return;
    }
    const getImageData = async () => {
      if (galleryName && albumName && index) {
        setImageData(
          await apiGetImage(
            galleryName,
            albumName,
            parseInt(index),
            loadingSize
            /* ImageSize.x */
          )
        );
        if (loadingSize === ImageSize.x) {
          setLoadingSize(ImageSize.s);
        } else if (loadingSize === ImageSize.s) {
          setLoadingSize(ImageSize.m);
        }
      }
    };
    getImageData();
  }, [albumName, galleryName, index, loadingSize, props.src]);

  if (!galleryName || !albumName || !index || !imageData) {
    return <h1>404 Page Not Found</h1>;
  }

  const handleSaveChanges = () => {
    apiPostImageInfo(galleryName, albumName, parseInt(index), imageInfo);
  };

  return (
    <div className="image-page">
      <div className="header">
        {props.hasAdminAccess ? (
          <div>
            <input
              className="title"
              onChange={(e) => {
                setImageInfo({
                  ...imageInfo,
                  name: e.target.value,
                });
              }}
              value={imageInfo.name}
            />
          </div>
        ) : (
          <h1 className="title">{imageInfo.name}</h1>
        )}
        <span className="button" onClick={props.handleBackButton}>
          &larr; Back
        </span>
        {imageInfo.saleStatus === ImageSaleStatus.ForSale ? (
          <a
            className="button"
            href={imageInfo.saleLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy!
          </a>
        ) : (
          <span className="button">Not for sale</span>
        )}
        {props.hasAdminAccess && (
          <span>
            <span className="button" onClick={props.handleImageDelete}>
              Delete
            </span>
            <select
              onChange={(e) => {
                if (e.target.value === "Not for sale") {
                  setImageInfo({
                    ...imageInfo,
                    saleStatus: ImageSaleStatus.NotForSale,
                  });
                }
                if (e.target.value === "For sale") {
                  setImageInfo({
                    ...imageInfo,
                    saleStatus: ImageSaleStatus.ForSale,
                  });
                }
              }}
              defaultValue={imageInfo.saleStatus}
            >
              <option>Not for sale</option>
              <option>For sale</option>
            </select>
            <span>
              {" "}
              <label>Sale Link: </label>
              <input
                type={"text"}
                defaultValue={imageInfo.saleLink}
                onChange={(e) => {
                  setImageInfo({ ...imageInfo, saleLink: e.target.value });
                }}
                disabled={imageInfo.saleStatus === ImageSaleStatus.NotForSale}
              />
            </span>
            <span className="button" onClick={handleSaveChanges}>
              Save Changes
            </span>
          </span>
        )}
      </div>
      <Image
        src={imageData}
        size={ImageSize.m}
        onClick={() => {
          if (
            imageInfo.saleLink &&
            imageInfo.saleStatus === ImageSaleStatus.ForSale
          ) {
            window.open(imageInfo.saleLink, "_blank");
          }
        }}
      />
    </div>
  );
};

export default ImagePage;
