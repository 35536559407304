import { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import {
  apiGetImage,
  apiGetAlbumLength,
  apiDeletePhoto,
  apiDeleteAlbum,
  apiGetImageInfo,
} from "../Api/ApiFunctions";
import Image from "../Components/Image";
import SubmitImagePopup from "../Components/SubmitImagePopup";
import { ImageInfo, ImageSize } from "../Models/ImageModel";
import NewPhoto from "../new_photo_icon.svg";
import ImagePage from "./ImagePage";

const AlbumPage = (props: { hasAdminAccess: boolean }): JSX.Element => {
  const [images, setImages] = useState<{ src: string; info: ImageInfo }[]>([]);
  const [loadedX, setLoadedX] = useState<boolean>(false);
  const [loadIndex, setLoadIndex] = useState<number>(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const [selectedNewImage, setSelectedNewImage] = useState<boolean>(false);
  const [isValidAlbum, setIsValidAlbum] = useState<boolean>(true);
  const loadedMsList = useRef(new Set());

  const { galleryName, albumName } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!albumName || !galleryName) {
      return;
    }
    setImages([]);
    setLoadedX(false);
    setLoadIndex(0);
    setSelectedImageIndex(-1);
    loadedMsList.current = new Set();

    const getAlbumLen = async () => {
      let album_length = await apiGetAlbumLength(galleryName, albumName);
      if (album_length === -1) {
        setIsValidAlbum(false);
        return;
      }

      let images: { src: string; info: ImageInfo }[] = [];
      for (let i = 0; i < album_length; i++) {
        // TODO FIX
        let imageInfo = await apiGetImageInfo(galleryName, albumName, i);
        images.push({ src: "", info: imageInfo });
      }
      setImages(images);
    };
    getAlbumLen();
  }, [albumName, galleryName]);

  useEffect(() => {
    if (images.length === 0 || !galleryName || !albumName) {
      return;
    }
    const fetchPhoto = async () => {
      if (loadIndex >= images.length) {
        if (!loadedX) {
          setLoadIndex(0);
          setLoadedX(true);
        }
        return;
      }
      const imageSize = !loadedX ? ImageSize.x : ImageSize.s;

      const image = await apiGetImage(
        galleryName,
        albumName,
        loadIndex,
        imageSize
      );
      let newImages = [...images];
      newImages[loadIndex].src = image;
      setImages(newImages);
      setLoadIndex(loadIndex + 1);
    };

    fetchPhoto();
  }, [albumName, galleryName, images, loadIndex, loadedX]);

  useEffect(() => {
    if (images.length === 0 || !galleryName || !albumName) {
      return;
    }
    if (selectedImageIndex >= 0 && selectedImageIndex < images.length) {
      if (loadedMsList.current.has(selectedImageIndex)) {
        return;
      } else {
        loadedMsList.current.add(selectedImageIndex);
      }
      const fetchPhoto = async () => {
        const image = await apiGetImage(
          galleryName,
          albumName,
          selectedImageIndex,
          ImageSize.m
        );
        let newImages = [...images];
        newImages[selectedImageIndex].src = image;
        setImages(newImages);
      };

      fetchPhoto();
    }
  }, [albumName, galleryName, images, selectedImageIndex]);

  const handleClickBackButton = () => {
    if (galleryName) {
      if (galleryName === "imageDB") {
        navigate(`/`);
        return;
      }
    }
    navigate(`/gallery/${galleryName}`);
  };

  if (!galleryName || !albumName) {
    return <h1>404 Page Not Found</h1>;
  }

  const handleImageDelete = async () => {
    if (!window.confirm("Delete Photo?")) {
      return;
    }
    await apiDeletePhoto(galleryName, albumName, selectedImageIndex);
    let newImages = [...images];
    newImages.splice(selectedImageIndex, 1);
    setImages(newImages);
    setSelectedImageIndex(-1);

    navigate(`/gallery/${galleryName}/album/${albumName}`);
  };

  const handleAlbumDelete = async () => {
    let promptInput = prompt(
      `Enter the album name to confirm deletion. (${albumName})`
    );
    if (promptInput) {
      if (promptInput === albumName) {
        await apiDeleteAlbum(galleryName, albumName);
        handleClickBackButton();
        window.location.reload();
        return;
      }
    }
    alert("Album names did not match.");
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="album-page">
            {isValidAlbum ? ( // change
              <div>
                {galleryName === "imageDB" ? (
                  <h1>Images</h1>
                ) : (
                  <div className="header">
                    <h1 className="title">{albumName}</h1>
                    <span className="button" onClick={handleClickBackButton}>
                      &larr; Back
                    </span>
                    {props.hasAdminAccess && (
                      <span className="button" onClick={handleAlbumDelete}>
                        Delete
                      </span>
                    )}
                  </div>
                )}

                {images.map((image, i) => (
                  <div key={i} className="image-container">
                    <h1>{image.info.name}</h1>
                    <Image
                      src={image.src}
                      size={ImageSize.s}
                      onClick={() => {
                        if (loadedX && loadIndex >= images.length) {
                          setSelectedImageIndex(i);
                          navigate(
                            `/gallery/${galleryName}/album/${albumName}/image/${i}`
                          );
                        }
                      }}
                    />
                  </div>
                ))}
                {props.hasAdminAccess && (
                  <img
                    className="new-image"
                    src={NewPhoto}
                    alt="new"
                    onClick={() => setSelectedNewImage(true)}
                  />
                )}
                {selectedNewImage && (
                  <SubmitImagePopup
                    gallery={galleryName}
                    album={albumName}
                    onExit={() => setSelectedNewImage(false)}
                  />
                )}
              </div>
            ) : (
              <h1>404 Page Not Found</h1>
            )}
          </div>
        }
      />

      <Route
        path="/image/:index/*"
        element={
          selectedImageIndex === -1 ? (
            <ImagePage
              hasAdminAccess={props.hasAdminAccess}
              handleImageDelete={handleImageDelete}
              handleBackButton={() => {
                setSelectedImageIndex(-1);
                navigate(`/gallery/${galleryName}/album/${albumName}`);
              }}
            />
          ) : (
            <ImagePage
              hasAdminAccess={props.hasAdminAccess}
              src={images[selectedImageIndex].src}
              info={images[selectedImageIndex].info}
              handleImageDelete={handleImageDelete}
              handleBackButton={() => {
                setSelectedImageIndex(-1);
                navigate(`/gallery/${galleryName}/album/${albumName}`);
              }}
            />
          )
        }
      />
      <Route path="*" element={null} />
    </Routes>
  );
};

export default AlbumPage;
